import { Link } from 'gatsby'
import React from 'react'
import Button from '../components/Button'

export default function NavLink(props) {
  return (
    <Button as={Link} to={props.to}>
      {props.children}
    </Button>
  )
}
