import React from 'react'
import styled from 'styled-components/macro'
import Divider from '../components/Divider'
import Layout from '../components/Layout'
import NavLink from '../components/NavLink'
import developmentskills from '../developmentskills.json'
import furtherskills from '../furtherskills.json'
import portfolio from '../portfolio.json'

export default function Home() {
  return (
    <Layout pageTitle="About me">
      <main>
        <section>
          <p>
            <span className="highlight">
              I am a Web & (cross-plattform) Mobile App developer from Hamburg,
              Germany.
            </span>
            <br />
            <br />A degree in International Business and a first career in
            market research, which focused on consumer decision making,{' '}
            <span className="italic">(keyword: System 1/System 2)</span>, gives
            me profound knowledge base to get my head around Marketing and UX/UI
            topics. I am, so to speak, powered by{' '}
            <a target="_blank" rel="noreferrer" href="https://neuefische.de/">
              neuefische
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://system1group.com/"
            >
              System1 Research
            </a>{' '}
            and{' '}
            <a target="_blank" rel="noreferrer" href="https://ipsos.de/">
              Ipsos
            </a>
            . Not to forget, staying true to my roots:{' '}
            <span className="highlight">Ostfriesentee and Franzbrötchen.</span>
            <br />
            <br />
            I love to create something from nothing - something that people
            benefit from in their everyday life. Or just really enjoy. Ideally
            both. And I love to make my customers happy, too. Frequent exchange,
            especially during project work, is important to me, ensuring
            everyone is convinced and content with the final result.
            <br />
            <br />
            <p>
              <span className="highlight">Coding with Soul. Soul Coding. </span>
            </p>
            <div>
              <NavLink to="/contact">&#10172; Drop me a line</NavLink>
            </div>
          </p>
        </section>
        <section>
          <section>
            <h2>Development skills</h2>
            <Divider />
            <ul>
              {developmentskills.map(skill => (
                <SkillsStyled key={skill.text}>{skill.text}</SkillsStyled>
              ))}
            </ul>
          </section>
          <section>
            <h2>...and other skills</h2>
            <Divider />
            <ul>
              {furtherskills.map(skill => (
                <SkillsStyled key={skill.text}>{skill.text}</SkillsStyled>
              ))}
            </ul>
          </section>
        </section>
        <Headline className="highlight">Portfolio excerpt</Headline>
        <PortfolioSection>
          {portfolio.map(project => (
            <section>
              <h2 key={project.title}>&#10172; {project.title}</h2>
              <h3 key={project.subtitle}>{project.subtitle}</h3>
              <Divider />
              <a target="_blank" rel="noreferrer" href={project.link}>
                <Screenshot
                  loading="lazy"
                  src={project.screenshot}
                  alt={project.altTextScreenshot}
                  width={project.screenshotWidth}
                  height={project.screenshotHeight}
                />
                <br />
                {project.titleScreenshot}
              </a>
              <br />
              <p className="italic">{project.subTitleScreenshot}</p>
              <p>{project.description}</p>
              <h3>Tech Stack Highlights:</h3>
              <ListStyled>
                <TechStack>{project.techStack}</TechStack>
              </ListStyled>
            </section>
          ))}
        </PortfolioSection>
      </main>
    </Layout>
  )
}

const Headline = styled.h1`
  display: inline-block;
  margin-bottom: 20px;
`
const ListStyled = styled.ul`
  margin-bottom: 24px;
`
const PortfolioSection = styled.section`
  background: var(--primary-light-opacity);
  padding: 16px;

  @media (min-width: 650px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`
const SkillsStyled = styled.li`
  display: inline-block;
  border: thin solid;
  border-color: var(--primary-light);
  font-size: 14px;
  margin: 5px;
  padding: 5px;
`
const Screenshot = styled.img`
  height: auto;
  max-height: 400px;
  margin-top: 20px;
  max-width: 100%;
  width: auto;
`
const TechStack = styled.li`
  display: inline-block;
  font-size: 14px;
  list-style-type: '|';
  margin-right: 2px;
`
