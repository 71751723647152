import styled from 'styled-components/macro'

export default styled.div`
  background-color: var(--primary-dark);
  height: 1px;
  width: 90%;

  @media (min-width: 650px) {
    width: 70%;
  }
`
